// Cutomize Bootstrap variables here
$font-family-sans-serif: 'Rubik';
$headings-font-family: 'Oswald';
$headings-font-weight: 400;

$success: #19a449;
$link-color: #333;

$grid-gutter-width: 15px;

$border-radius: 0.1875rem;
$border-radius-sm: 0.1875rem;
$border-radius-lg: 0.1875rem;
$border-radius-xl: 0.1875rem;
$border-radius-xxl: 0.1875rem;

$primary: #333;
$info: #fff;

$close-font-weight: normal;
$close-text-shadow: none;

$navbar-height: 60px;
$navbar-border-radius: 0;
$navbar-default-bg: #fff;
$navbar-default-border: #fff;
$navbar-default-color: #333;
$navbar-default-link-color: #333;

$breadcrumb-color: #888;
$breadcrumb-active-color: #888;
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 25px;
$breadcrumb-margin-bottom: 0px;

$carousel-control-width: 40px;
$carousel-indicator-border-color: transparent;
$carousel-indicator-active-bg: #000;

$list-group-link-color: #333;
$list-group-border-radius: 0;

$progress-border-radius: 0;

$table-cell-padding: 20px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1145px
);

$font-size-base: 0.875rem; // ~14px
$h1-font-size: $font-size-base * 2.6; // ~36px
$h2-font-size: $font-size-base * 2.15; // ~30px
$h3-font-size: $font-size-base * 1.7; // ~24px
$h4-font-size: $font-size-base * 1.25; // ~18px
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.85; // ~12px

$min-contrast-ratio: 3;

$table-cell-padding-y: 1.25rem;
$table-cell-padding-x: 1.25rem;
