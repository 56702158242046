@import 'variables';
@import 'bootstrap';

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2);
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4 {
  letter-spacing: 1px;
}

.progress {
  margin: 0;
  box-shadow: none;
  height: 10px;
  background-color: transparent;
}

.btn-block {
  text-transform: uppercase;
  width: 100%;
  display: block;
}

.btn-lg {
  font-size: inherit;
  padding: 14px 16px;
}

.form-control {
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

form {
  & .form-label {
    font-weight: 700;
  }

  & input,
  select {
    margin-bottom: 15px;
  }
}

// Copied and modified from .form-control-focus bootstrap mixin
@mixin input-control-focus($color: #66afe9) {
  border-color: $color;
  outline: 0;
}

.input-focus {
  @include input-control-focus();
}

.breadcrumb {
  a {
    color: $breadcrumb-color;
  }

  @media (max-width: (720px + $grid-gutter-width)) {
    padding: 8px 0;
  }
}

.carousel-indicators {
  bottom: 0;
  margin-bottom: 0;

  li,
  li.active {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: 0 3px;
  }

  li {
    background: #9b9b9b;
  }
}

.navbar-brand {
  padding: 22px $navbar-padding-x;
}

.navbar-brand > img {
  display: inline;
}

// Disable inset shadows
textarea,
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='search'],
input[type='number'] {
  -webkit-appearance: none;
}

.flush {
  &:not(.row) {
    padding-left: 0;
    padding-right: 0;
  }

  &.row {
    margin-left: 0;
    margin-right: 0;
  }
}

.flush-xs {
  @media (max-width: 767px) {
    &:not(.row) {
      padding-left: 0;
      padding-right: 0;
    }

    &.row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
/*This is the transition for when the user logs into the CAD*/
.full {
  position: fixed;
  inset: 0;
  z-index: 116;
  display: grid;
  border: none;
  background: black;
}
.full:before {
  content: '';
  grid-area: 1/1;
  --s: 67px; /* shape size */
  --m: 4px; /* line thickness */

  --v1: black 119.5deg, #0000 120.5deg;
  --v2: white 119.5deg, #0000 120.5deg;
  background: conic-gradient(
      at var(--m) calc(var(--s) * 0.5777),
      transparent 270deg,
      white 0deg
    ),
    conic-gradient(
      at calc(100% - var(--m)) calc(var(--s) * 0.5777),
      white 90deg,
      transparent 0deg
    ),
    conic-gradient(from -60deg at 50% calc(var(--s) * 0.8662), var(--v1)),
    conic-gradient(
      from -60deg at 50% calc(var(--s) * 0.8662 + 2 * var(--m)),
      var(--v2)
    ),
    conic-gradient(
      from 120deg at 50% calc(var(--s) * 1.4435 + 3 * var(--m)),
      var(--v1)
    ),
    conic-gradient(
      from 120deg at 50% calc(var(--s) * 1.4435 + var(--m)),
      var(--v2)
    ),
    linear-gradient(
      90deg,
      black calc(50% - var(--m)),
      white 0 calc(50% + var(--m)),
      black 0
    );
  background-size: calc(var(--s) + 2 * var(--m))
    calc(var(--s) * 1.732 + 3 * var(--m));
  -webkit-mask: linear-gradient(
      -60deg,
      #0000 calc(50% - 4 * var(--s)),
      #000,
      #0000 calc(50% + 4 * var(--s))
    )
    right/300% 100% no-repeat;
  animation: f 3s infinite linear;
}
.full:after {
  grid-area: 1/1;
  font-family: monospace;
  font-weight: bold;
  font-size: 13vmin;
  margin: auto;
  mix-blend-mode: difference;
  color: black;
  border: none;
}

@keyframes f {
  100% {
    -webkit-mask-position: left;
  }
}

$list-group-active-bg: $list-group-hover-bg;
$list-group-active-color: $list-group-link-color;
